.network-alert {
  &-container {
    position: relative;
    width: 24px;
    height: 24px;
  }
  &-icon {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: var(--theme-page-text);
  }
  &-offline {
    color: red;
  }
}
