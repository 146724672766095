header {
  display: grid;
  grid-template-columns: 11fr 1fr;
}

.header {
  padding: 10px;
  &-nav {
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    ul {
      display: flex;
      flex-direction: row;
      grid-gap: 10px;
    }
    a {
      transition: color 0.5s ease-in-out, height 0.5s ease-in-out;
      color: var(--theme-page-text);
    }
  }
  &-status {
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    justify-content: end;
  }
}
