.add-players {
  &-wrapper {
    border-radius: 10px;
    color: var(--theme-page-text);
    background-color: var(--theme-component-background-color);
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
  }
  &-header {
    background-color: #9dcafd;
  }
}
